<template>
    <div class="firestick-subtext-container">
        <p class="firestick-subtext" :class="index === 0 ? 'firestick-margin-text' : ''" v-for="(item, index) in texts"
            :key="index">
            {{ $t(item) }}
        </p>
    </div>
</template>
<script>
export default {
    data() {
        return {
            texts: [
                'faq.firestick.subtext.weWillShow',
                'faq.firestick.subtext.recommend'
            ]
        }
    }
}
</script>

<style scoped>
.firestick-subtext-container {
    margin-bottom: 20px;
}

.firestick-subtext {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;

    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;

    word-wrap: break-word;
}

.firestick-margin-text {
    margin: 16px 0;
}
</style>
